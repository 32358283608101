<template>
    <div class="detector-detail-page">
        <page-title :title="info.title" style="border: none;" v-if="info">
            <template slot="center">
                <span class="fonts-12" v-html="currentSubtask && currentSubtask.description"></span>
            </template>
            <template slot="tools">
                <span class="fonts-14 margin-r-10">相似度</span>
                <el-select v-model="filterValue" placeholder="请选择" size="medium" clearable @change="filterChangeEvent" style="width:120px;">
                  <el-option v-for="item in filterOptions" :label="item.name" :key="item.value" :value="item.value"></el-option>
                </el-select>
                <el-button type="primary" size="small" round icon="el-icon-download" v-permission="`tools:detector:detailRouter:download`" style="margin-left:10px;" :loading="downloadLoading" @click="downloadResult">下载报告</el-button>
            </template>
        </page-title>
        <div class="flex flex-align-center padding-b-15 border-b">
            <div class="engine-card-title">相似度</div>
            <div class="engine-card-list">
                <template v-for="(item,index) in subtasks">
                    <div :class="['item',currentSubtask&&currentSubtask.engineCode === item.engineCode?'active':'']" :key="index" @click="engineItemClick(item)">
                        <div class="name">
                            <span>{{item.engineName}}：</span>
                            <span class="color-DE0000" v-if="item.status === 'S'">{{item.similarity}}%</span>
                            <span class="color-DE0000" v-else-if="item.status === 'F'">
                                <el-tooltip :content="item.statusDesc" placement="top">
                                    <svg-icon name="ib-warning-o"></svg-icon>
                                </el-tooltip>
                                <span class="margin-l-8 color-link" v-permission="`tools:detector:detailRouter:retry`" @click.stop="retryBtnClick(item)" v-if="item.allowRetry">重试</span>
                            </span>
                            <span class="fonts-18" v-else><i class="el-icon-loading"></i></span>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- <div class="page-tools-content" style="margin-bottom:0;">
            <div class="left">
                <el-button type="primary" size="small" round icon="el-icon-download" :loading="downloadLoading" @click="downloadResult">下载报告</el-button>
            </div>
            <div class="flex-2 flex flex-align-center flex-justify-center">
                <span class="fonts-12" v-html="currentSubtask && currentSubtask.description"></span>
            </div>
            <div class="right">
                <span class="fonts-14 margin-r-10">相似度</span>
                <el-select v-model="filterValue" placeholder="请选择" size="medium" clearable @change="filterChangeEvent" style="width:120px;">
                  <el-option v-for="item in filterOptions" :label="item.name" :key="item.value" :value="item.value"></el-option>
                </el-select>
            </div>
        </div> -->
        
        <el-table :data="list">
          <el-table-column label="序号" type="index" width="60px"></el-table-column>
          <el-table-column :label="`原文（${info && info.sourceLang ? LANGUAGE_DICT[info.sourceLang].name:''}）`" prop="sourceText"></el-table-column>
          <el-table-column :label="`译文（${info && info.sourceLang ? LANGUAGE_DICT[info.targetLang].name:''}）`" prop="targetText"></el-table-column>
          <el-table-column label="相似度" width="70px">
              <template slot-scope="scope">
                  <div :class="[scope.row.similarity*1===100?'color-DE0000':(scope.row.similarity*1<100&&scope.row.similarity*1>=90)?'color-FF9900':'']" style="font-weight: 500;">{{scope.row.similarity}}%</div>
              </template>
          </el-table-column>
          <el-table-column :label="`${currentSubtask && (currentSubtask.engineName||'')}翻译`">
              <template slot-scope="scope">
                  <div v-html="scope.row.markedTargetText"></div>
              </template>
          </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :current-page="pageNumber"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import { toolsApi, commonApi } from '@/utils/api';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
export default {
    components: { FullLoading, PageTitle, AlertDialog },
    data() {
        return {
            info: null,
            list: [],
            pageSize: 10,
            pageNumber: 1,
            total: 0,
            downloadLoading: false,
            subtasks: [],
            currentSubtask: null,
            subtaskTimer: null,
            filterOptions: [
                {value:'minSimilarity=&maxSimilarity=79', name:'79%以下'},
                {value:'minSimilarity=80&maxSimilarity=89', name:'80%-89%'},
                {value:'minSimilarity=90&maxSimilarity=99', name:'90%-99%'},
                {value:'minSimilarity=100&maxSimilarity=', name:'100%'},
            ],
            filterValue: null,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initSubTaskList(type) {
            let url = `${toolsApi.queryMTISubtask}?taskId=${this.$route.query.id}`;
            this.$ajax.get(url).then(res=> {
                if (res.status === 200){
                    this.subtasks = res.data.subtasks;
                    this.info = res.data.task;
                    if (!this.currentSubtask){
                        for (let item of this.subtasks){
                            if (item.status === 'S'){
                                this.currentSubtask = item;
                                break;
                            }
                        }
                    }
                    if (type === 'init' && this.currentSubtask){
                        this.initSubTaskDetail();
                    }
                    let taskStatus = true;
                    for (let item of this.subtasks){
                        if (item.status !== 'S'){
                            taskStatus = false;
                            break;
                        }
                    }
                    if(taskStatus){
                        clearInterval(this.subtaskTimer);
                        this.subtaskTimer = null;
                    }
                }
            })
        },
        initSubTaskDetail() {
            let url = `${toolsApi.queryMTISubtaskDetail}?subtaskId=${this.currentSubtask.subtaskId}` + 
                        `&pageNo=${this.pageNumber}` + 
                        `&pageSize=${this.pageSize}`;
            if(this.filterValue){
                url = `${url}&${this.filterValue}`; 
            }
            this.$ajax.get(url).then(res=>{
                if (res.status === 200){
                    this.list = res.data.results.records;
                    this.total = res.data.results.total;
                }
            })
            
        },
        filterChangeEvent(val){
            this.pageNumber = 1;
            this.initSubTaskDetail();
        },
        engineItemClick(item){
            if (item.status === 'S'){
                this.currentSubtask = item;
                this.pageNumber = 1;
                this.initSubTaskDetail();
            }else if(item.status === 'F'){
                this.$message.error('当前引擎检测失败');
            }else{
                this.$message.error('当前引擎检测中，请稍后');
            }
        },
        retryBtnClick(item){
            let url = `${toolsApi.retrySubtask}?subtaskId=${item.subtaskId}`;
            this.$ajax.get(url).then(res=>{
                if (res.status === 200){
                    this.$message.success('已重试');
                    this.initSubTaskList();
                }
            });
        },
        downloadResult(){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: this.info.title,
                metadataIdList: [this.info.taskId],
                metadataType: 'TOOLKIT_MTD',
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() =>{
                
            });
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initSubTaskDetail();
        }
    },
    mounted() {
        let self = this;
        this.initSubTaskList('init');
        this.subtaskTimer = setInterval(()=>{
            self.initSubTaskList();
        },5000);
    },
    destroyed() {
        clearInterval(this.subtaskTimer);
    }
    
}
</script>
<style lang="scss" scoped>
.detector-detail-page{
    .engine-card-title{
        font-size: 15px;
        font-weight: 500;
        padding-right: 10px;
        // margin-top: 30px;
    }
    .engine-card-list{
        // margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        grid-gap: 10px;
        .item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #e6e6e6;
            transition: background-color .3s linear;
            border-radius: 50px;
            padding: 0 15px;
            height: 32px;
            font-size: 14px;
            cursor: pointer;
            .name{
                font-weight: 600;
            }
            .num{
                display: flex;
                align-items: center;
                margin-top: 8px;
            }

            &.active{
                background-color: #c7ebdd;
            }
        }
    }
    .page-title-component{
        ::v-deep .el-input__inner{
            border-radius: 50px;
        }
    }
}

</style>
